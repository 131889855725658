import profileImage from './assets/profielfoto.jpg';

function ProfileComponent() {
    return <div id="profile" className="profile-container" style={{
        backgroundImage: `url(${profileImage})`,
        backgroundSize: "100%",
        minHeight: "442px",
        marginTop: "16px",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#77cdda"
    }}>
        <div className='profile-text-container' >
            <p>
                I am a  Dutch illustrator with English roots.I work and live in the Netherlands with my husband and two children. After a year at art school at the Surrey Institute of Art & Design in England, I completed my studies in International Management in Rotterdam and Munster.
            </p>
            <p>
                For years I worked in marketing and graphic design during which I rediscovered my old love for drawing and illustration.  Recently I graduated from the year-long course Visual Storytelling at the Venster Academy of Art and Design.
            </p>
            <p>
                Currently I am illustrating different picture books written by other authors. My short-term goal is to publish a picture book written and illustrated by me in the future.
            </p>
            <p>
                I always try to look for the mysterious and fairy-tale elements in everyday mundane situations. “The world around us is wonderful and I always try to look at it with childlike wonder.”
            </p>
            <p>
                My work is colourful, fairytale-like and decorative.
            </p>
        </div>

        <div role="button" className="goto-top" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>🔝</div>
    </div>
}

export default ProfileComponent;