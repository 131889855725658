import titleImage from './assets/mainTitle.png';
import stickerImage from './assets/findout.png';
import ImageContainer from './ImageContainer';
import ProfileComponent from './Profile';
import contactImage from './assets/contact.png';
import { useEffect, useLayoutEffect } from 'react';

function JettieScholtenComponent() {

    useEffect(() => {
        document.title = "Jettie Scholten Illustraties";
    }, [])




    return <div className='jettie-component' style={{ width: "100%", maxWidth: "1048px" }}>
        <div className="fullsize-image-container">
            <img id="title-image" width="860px" src={titleImage} />
            {/*<a href="#profile"><img className='sticker' src={stickerImage} /></a>*/}
            <img id="sticker" className='sticker' src={stickerImage} onClick={() => { window.scrollTo({ top: document.getElementById('profile')?.getBoundingClientRect().top, behavior: 'smooth' }) }} />
        </div>

        <ImageContainer />
        <div style={{ width: "100%", }}>
            <img src={contactImage} style={{ width: "90%", marginLeft: "32px" }} />
        </div>
        <ProfileComponent />

        <div style={{ display: "flex", justifyContent: "center" }}>


            Jettie Scholten - www.jettiescholten.nl - info@jettiescholten.nl<br></br>

            All text and images © Jettie Scholten / credits & disclaimer
        </div>
    </div>
}

export default JettieScholtenComponent;