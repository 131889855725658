import JettieScholtenComponent from "./Components/JettieScholtenComponents/JettieScholtenComponent";
import MainComponent from "./Components/Main/MainComponent";

function App() {
  const refr = window.location.href;
  const appContent = refr.includes("jestermarketing") ? <MainComponent /> : <JettieScholtenComponent />;

  return (
      <div className="App">
        {appContent}
      </div>
  );
}

export default App;
