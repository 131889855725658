import image1 from './assets/1.jpg';
import image2 from './assets/2.jpg';
import image3 from './assets/3.jpg';
import image4 from './assets/4.jpg';
import image5 from './assets/5.jpg';
import image6 from './assets/6.jpg';
import image7 from './assets/7.jpg';
import image8 from './assets/8.jpg';
import image9 from './assets/9.jpg';
import image10 from './assets/10.jpg';
import image11 from './assets/11.jpg';
import image12 from './assets/12.jpg';
import image13 from './assets/13.jpg';
import image14 from './assets/14.jpg';
import image15 from './assets/15.jpg';
import image16 from './assets/16.jpg';
import image17 from './assets/17.jpg';
import image18 from './assets/18.jpg';
import image19 from './assets/19.jpg';
import image20 from './assets/20.jpg';
import image21 from './assets/21.jpg';

function ImageContainer() {
    return <div style={{width:"100%"}} >
<ImageRow images={[image1,image2,image3]}/>
<ImageRow images={[image4,image5,image6]}/>
<ImageRow images={[image7,image8,image9]}/>
<ImageRow images={[image10,image11,image12]}/>
<ImageRow images={[image13,image14,image15]}/>
<ImageRow images={[image16,image17,image18]}/>
<ImageRow images={[image19,image20,image21]}/>
    </div>
}

function ImageRow(props:{images:any[]}) {
    return <div className='image-row'>
    <ImageCell position="down" imageSource={props.images[0]}/>
<ImageCell position="up" imageSource={props.images[1]}/>
<ImageCell position="down" imageSource={props.images[2]}/>
    </div>
}

function ImageCell(props: { position: "up" | "down", imageSource:any }) {
    return <div className='image-cell'>
        {props.position === "down" && <div className="filling" />}
        <img src={props.imageSource}  style={{width:"100%"}}/>
        {props.position === "up" && <div className="filling" />}
    </div>

}

export default ImageContainer;